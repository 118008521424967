/* App.css */
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html,
body {
    height: 100%;
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

* {
    line-height: calc(1em + 0.5rem);
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

.app-container {
    display: block;
    width: 900px;
    /* Screen display */
    justify-content: center;
    padding-left: 13rem;
    padding-right: 13rem;
    font-weight: bold;

    font-family: "Courier New", Courier, monospace;
}
.back-id {
    height: 700px;
    width: 900px;
    object-fit: cover;
    padding-left: 13rem;
    padding-right: 13rem;
    top: 50px
}
.main-content {
    border: 1px solid black;
    height: 700px; /* Screen display */
    background-color: white;
    display: grid;
    grid-template-columns: repeat(13, 1fr);
}

.sidebar {
    display: block;
    grid-column: span 3 / span 3;
}

.sidebar img {
    object-fit: fill;
    max-height: 700px; /* Screen display */
}

.content {
    grid-column: span 10 / span 10;
    position: relative;
}

.background {
    position: absolute;
}

.foreground {
    z-index: 50;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 80px 10px;
}

.foreground-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;
}

.foreground-title {
    font-size: 1.875rem;
    font-weight: bold;
}

.foreground-id-card {
    width: 250px;
    height: 250px;
    /*background-color: azure;*/
}

.foreground-info {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.foreground-info-item p {
    text-align: center;
    color: gray;
    font-size: 1.125rem;
    font-weight: bold;
    margin: 0;
    font-family: "Courier New", Courier, monospace;
}

.foreground-info-item span {
    color: gray;
    text-align: center;
}

.foreground-info-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
}

.id-card-back {
    border: 1px solid black;
    height: 600px; /* Screen display */
    grid-template-columns: repeat(4, 1fr);
}

/* Print-specific styles */
