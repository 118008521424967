.chat-container {
    position: relative;
    /*max-width: 600px;*/
    margin-right: 25px;
}

.chat-input-form {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: transparent; /* Adjust background color as needed */
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: add a shadow at the bottom */
}

.input-container {
    display: flex;
    align-items: stretch;
}

textarea {
    flex: 1;
    resize: none;
    padding: 10px;
    font-size: 16px;
    color: inherit;
    background-color: transparent; /* Adjust background color as needed */
}

button {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}
